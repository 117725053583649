import { apiServiceInstance } from "@/core/helpers/utils";
import { ApiResponse, ICapSuaChuaResponseBase, PaginationResponseBase } from '../interfaces/ApiResponses';
import { ICapSuaChuaFilterParams } from '@/core/interfaces/ApiRequest.interface';

export class CapSuaChuaService {
  static list({
    current_page,
    per_page,
    s,
  }: ICapSuaChuaFilterParams): Promise<{
    data: ApiResponse<PaginationResponseBase<ICapSuaChuaResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get('/api/v1/cap-sc/danh-sach', {
      params: {
        page: current_page || 1,
        ...(s && { s }),
      },
      headers: {
        perpage: per_page || 1000,
      },
    });
  }

  static get(id: number): Promise<{
    data: ApiResponse<ICapSuaChuaResponseBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(`/api/v1/cap-sc/chi-tiet/${id}`);
  }
}
